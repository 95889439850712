.services {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 100px;
  width: 100%;
  min-height: 80vh;
}

.aboutMe {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}

.aboutMeTitle {
  font-size: 40px;
  margin-bottom: 30px;
  width: 200px;
}

.servicesBioBody {
  font-size: 30px;
  text-align: left;
  background: rgb(245, 248, 249);
  color: rgb(34, 33, 32, 0.6);
  border-radius: 10px;
  max-width: 700px;
  padding: 15px;
  margin-bottom: 30px;
  margin-top: 0px;
}

.servicesContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}

.skillsComp {
  display: flex;
  flex-direction: column;
  text-align: left;
  background: rgb(245, 248, 249);
  border-radius: 10px;
  max-width: 700px;
  padding: 15px;
}

.servContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.skill {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: rgb(34, 33, 32, 0.6);
}
