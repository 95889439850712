.servicesComp {
  display: flex;
  flex-wrap: wrap;
  font-size: 30px;
  width: 100%;
  justify-content: space-between;
}

.sComptitle {
  color: rgb(41, 48, 60);

  margin: 0px;
}

.ServicesCompR {
  display: flex;
  flex-wrap: wrap;
  background: rgb(245, 248, 249);
  color: rgb(34, 33, 32, 0.6);
  margin-bottom: 50px;
  width: 50%;
  height: max-content;
  height: max-content;
  border-radius: 10px;
}

.serviceCompItem {
  display: flex;
  align-items: center;
}

.servicesCompTitle {
  padding-right: 10px;
  padding-left: 10px;
}

.dot {
  background: rgb(34, 33, 32, 0.6);
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.hrServices {
  border: 1px dashed black;
  width: 100px;
  margin: 0px 10px;
}

@media only screen and (max-width: 1050px) {
  .hrServices {
    border: 0px dashed black;
    width: 0px;
    margin: 0px 10px;
  }
}
