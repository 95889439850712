.app {
  position: relative;
  display: flex;
  justify-content: center;
  color: rgb(41, 48, 60);
  font-family: 'Robot+Slab';
}

.components {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  margin-right: 50px;
  padding: 0px 15px;
  width: 100%;
}

.footerTitleMobile {
  display: none;
}

.impressumMobile {
  display: none;
}

@media only screen and (max-width: 600px) {
  .components {
    margin-left: 0px;
    margin-right: 0px;
    margin: 0px auto;
  }

  .footerMobile {
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 50px;
    background: rgb(41, 48, 60);
    color: white;
  }
  .footerTitleMobile {
    display: block;
  }

  .impressumMobile {
    display: block;
    text-decoration: none;
    color: white;
  }
}
