.portFComp {
  display: flex;
  flex-wrap: wrap-reverse;
  width: 100%;
  margin-bottom: 150px;
  justify-content: space-evenly;
}

.reverse {
  display: flex;
  width: 100%;
  flex-wrap: wrap-reverse;
  flex-direction: row-reverse;
  justify-content: space-evenly;
}

.portFCompImg {
  height: 500px;
  width: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 0.2s ease-in-out;
}
/* .portFCompImg:hover {
  transform: scale(1.8) translate(-25%, 10%);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
} */

.portFCompImgUneven {
  height: 500px;
  width: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 0.2s ease-in-out;
}

/* .portFCompImgUneven:hover {
  transform: scale(1.8) translate(25%, 10%);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
} */

.portFCompText {
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 20px;
  max-width: 650px;
}

.portFCompTitle {
  color: rgb(41, 48, 60);
  font-size: 40px;
  margin-top: 0px;
}

.portFCompBody {
  font-size: 30px;
}

.portFBtnActive {
  width: max-content;
  padding: 10px 15px;
  color: rgb(255, 255, 255);
  background: rgb(110, 118, 126);
  border-radius: 10px;
  font-size: 20px;
  outline: none;
  transition: all 0.2s ease-in-out;
  border: none;
}

.portFBtnActive:hover {
  cursor: pointer;
  background: rgba(15, 44, 77, 0.75);
}

.portFBtnInactive {
  background: rgba(15, 44, 77, 0.216);
}

@media only screen and (max-width: 950px) {
  .portFComp {
    margin-bottom: 100px;
  }

  .portFCompImg {
    height: 216px;
    width: 350px;
  }

  .portFCompImgUneven {
    height: 216px;
    width: 350px;
  }
}

@media only screen and (max-width: 1592px) {
  .portFCompImg {
    margin-bottom: 40px;
  }

  .portFCompImgUneven {
    margin-bottom: 40px;
  }

  .portFCompImg:hover {
    transform: scale(1) translate(0%, 0%);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  .portFCompImgUneven:hover {
    transform: scale(1) translate(0%, 0%);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
}
