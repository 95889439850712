.lines {
  display: flex;
  position: fixed;
  justify-content: space-between;
  left: 0px;
  top: 0px;
  width: 50px;
  height: 100%;
  z-index: 99;
}
.lLine {
  position: relative;
  width: 12px;
  height: 100%;
  /* background: rgb(21, 126, 252); */
  background: rgb(41, 48, 60);
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}

.mLine {
  position: relative;
  width: 12px;
  height: 92%;
  /* background: rgb(29, 100, 191); */
  background: rgb(80, 89, 102);

  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  z-index: 99;
  transition: all 0.2s ease-in-out;
}

.rLine {
  position: relative;
  width: 12px;
  height: 81%;
  /* background: rgb(13, 42, 79); */
  background: rgb(110, 118, 126);

  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  z-index: 2;
}

.btnLabel {
  font-family: 'Robot+Slab';

  position: absolute;
  bottom: 0px;
  left: 12px;
  min-width: max-content;
  padding: 10px 15px;
  color: rgba(255, 255, 255, 0);
  background: rgba(255, 255, 255, 0);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 20px;
  outline: none;
  transition: all 0.2s ease-in-out;
  border: 0px solid rgba(255, 255, 255, 0);
  z-index: -99;
  transform: scaleY(0);
}

.btnLabelMobile {
  display: none;
}

.btnMLabelVis {
  color: rgba(80, 89, 102);
  /* background: rgba(29, 99, 191, 0.504); */
  background: rgb(255, 255, 255);
  border: 1px solid rgba(80, 89, 102);
  transform: scaleY(1);
  backdrop-filter: blur(8px);
  width: 190px;
}

.btnMLabelVis::before {
  position: absolute;
  content: '';
  top: -31px;
  left: -1px;
  width: 30px;
  height: 30px;
  background: rgb(255, 255, 255);
  /* box-shadow: -12px 0px 0 0 rgba(29, 99, 191, 0.504); */
  box-shadow: -12px 0px 0 0 rgba(80, 89, 102);
  backdrop-filter: blur(8px);

  z-index: -99;
  border-bottom-left-radius: 10px;
}

.btnRLabelVis {
  color: rgb(255, 255, 255);
  background: rgba(110, 118, 126);
  transform: scaleY(1);
  backdrop-filter: blur(8px);
  width: 130px;
}

.btnRLabelVis::before {
  position: absolute;
  content: '';
  top: -30px;
  left: 0px;
  width: 30px;
  height: 30px;
  background: rgb(255, 255, 255);
  box-shadow: -12px 0px 0 0 rgba(110, 118, 126);

  backdrop-filter: blur(8px);

  z-index: -99;
  border-bottom-left-radius: 10px;
}

.btnLabel:hover {
  box-sizing: border-box;
  opacity: 0.9;
  cursor: pointer;
  width: inherit;
  /* border-right: 3px solid rgba(0, 0, 0, 0.5); */
}

.mLine:hover .btnLabel {
  position: absolute;
  bottom: 0px;
  left: 12px;
  width: 190px;
  padding: 10px 15px;
  color: rgb(255, 255, 255);
  background: rgba(80, 89, 102, 0.75);
  border: 1px solid rgba(80, 89, 102, 0);
  backdrop-filter: blur(8px);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 20px;
  outline: none;
  transition: all 0.2s ease-in-out;
  transform: scaleY(1);
}

.mLine:hover .btnLabel::before {
  position: absolute;
  content: '';
  top: -31px;
  left: -1px;
  width: 30px;
  height: 30px;
  background: rgb(255, 255, 255);
  box-shadow: -12px 0px 0 0 rgba(80, 89, 102, 0.75);

  z-index: 99;
  border-bottom-left-radius: 10px;
}

.mLine:hover {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 15px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.rLine:hover .btnLabel {
  position: absolute;
  bottom: 0px;
  left: 12px;
  width: 130px;
  padding: 10px 15px;
  color: rgb(255, 255, 255);
  /* background: rgba(13, 42, 79, 0.504); */
  background: rgba(110, 118, 126, 0.75);

  backdrop-filter: blur(8px);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 20px;
  outline: none;
  transition: all 0.2s ease-in-out;
  transform: scaleY(1);
}

.rLine:hover .btnLabel::before {
  position: absolute;
  content: '';
  top: -30px;
  left: 0px;
  width: 30px;
  height: 30px;
  background: rgb(255, 255, 255);
  /* box-shadow: -12px 0px 0 0 rgba(13, 42, 79, 0.504); */
  box-shadow: -12px 0px 0 0 rgba(110, 118, 126, 0.75);

  z-index: 99;
  border-bottom-left-radius: 10px;
}

.rLine:hover {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 15px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.lineVisible {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 15px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.footer {
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  bottom: 0px;
  left: 12px;
  width: 100vw;
  height: 50px;
  background: rgb(255, 255, 255);
  color: white;
  align-items: center;
  transform: translate(0%, 100%);
  transition: all 0.2s ease-in-out;
}

.footerVis {
  transform: translate(0%, 0%);
  /* background: rgb(21, 126, 252); */
  background: rgb(41, 48, 60);
}

.footerVis::before {
  position: absolute;
  content: '';
  top: -30px;
  left: 0px;
  width: 30px;
  height: 30px;
  background: rgb(255, 255, 255);
  /* box-shadow: 0 15px 0 0 rgb(21, 126, 252); */
  box-shadow: 0 15px 0 0 rgb(41, 48, 60);

  z-index: 99;
  border-bottom-left-radius: 15px;
}

.footerTitle {
}

.impressum {
  text-decoration: none;
  color: white;
}

.btnMarker {
  position: absolute;
  bottom: 0%;
  left: 50%;
  width: 30%;
  height: 10px;
  transform: translate(-50%, -50%);

  border-radius: 10px;
  background: white;
  transition: all 0.2s ease-in-out;
}

.mLine:hover .btnMarker {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.rLine:hover .btnMarker {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

@media only screen and (max-width: 1050px) {
  .lLine {
    height: 100%;
  }

  .mLine {
    height: 45vh;
  }

  .rLine {
    height: 20vh;
  }

  .impressum {
    text-decoration: none;
    color: white;
  }
}

@media only screen and (max-width: 600px) {
  .lines {
    display: none;
  }

  .btnLabelMobile {
    display: block;
    font-family: 'Robot+Slab';

    min-width: 100%;
    padding: 10px 15px;
    color: rgba(255, 255, 255);
    background: rgb(80, 89, 102);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 20px;
    outline: none;
    transition: all 0.2s ease-in-out;
    border: 0px solid rgb(80, 89, 102);
    border-radius: 10px;
    margin-bottom: 40px;
  }

  .btnLabelUnMarked {
    background: white;
    border: 1px solid rgb(80, 89, 102);
    color: rgb(80, 89, 102);
  }
}
