@import url(https://fonts.google.com/specimen/Roboto+Slab?query=roboto#standard-styles);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'Roboto-Slab' sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  position: relative;
  display: flex;
  justify-content: center;
  color: rgb(41, 48, 60);
  font-family: 'Robot+Slab';
}

.components {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  margin-right: 50px;
  padding: 0px 15px;
  width: 100%;
}

.footerTitleMobile {
  display: none;
}

.impressumMobile {
  display: none;
}

@media only screen and (max-width: 600px) {
  .components {
    margin-left: 0px;
    margin-right: 0px;
    margin: 0px auto;
  }

  .footerMobile {
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 50px;
    background: rgb(41, 48, 60);
    color: white;
  }
  .footerTitleMobile {
    display: block;
  }

  .impressumMobile {
    display: block;
    text-decoration: none;
    color: white;
  }
}

.bio {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'Tangerine';
  margin-bottom: 200px;
}

.bioTop {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 70px;
}

.bioBot {
  margin-top: 100px;
  align-self: center;
}

.bioTopL {
  display: flex;
}

.bioTopText {
  margin-top: 100px;

  text-align: left;
}

.bioTitle {
  font-size: 50px;
}

.bioSubTitle {
  font-size: 30px;
  color: rgb(34, 33, 32, 0.6);
}

.bioContent {
  font-size: 30px;
  color: rgb(34, 33, 32, 0.6);

  max-width: 500px;
}

.bioImg {
  margin-top: 100px;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  border-radius: 100%;
  width: 350px;
  height: 350px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.25s ease-in-out;
}

.bioImg:hover {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
  transition: all 0.25s ease-in-out;
}

.bioBody {
  font-size: 30px;
  max-width: 700px;
  text-align: center;
}

.devider {
  border: 1px solid rgb(34, 33, 32, 0.6);
  width: 50%;
  margin: 100px auto;
}

@media only screen and (max-width: 1053px) {
  .bioTop {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }
}

@media only screen and (max-width: 500px) {
  .bioImg {
    width: 300px;
    height: 300px;
    margin-top: 180px;
  }
}

.services {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 100px;
  width: 100%;
  min-height: 80vh;
}

.aboutMe {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}

.aboutMeTitle {
  font-size: 40px;
  margin-bottom: 30px;
  width: 200px;
}

.servicesBioBody {
  font-size: 30px;
  text-align: left;
  background: rgb(245, 248, 249);
  color: rgb(34, 33, 32, 0.6);
  border-radius: 10px;
  max-width: 700px;
  padding: 15px;
  margin-bottom: 30px;
  margin-top: 0px;
}

.servicesContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}

.skillsComp {
  display: flex;
  flex-direction: column;
  text-align: left;
  background: rgb(245, 248, 249);
  border-radius: 10px;
  max-width: 700px;
  padding: 15px;
}

.servContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.skill {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: rgb(34, 33, 32, 0.6);
}

.servicesComp {
  display: flex;
  flex-wrap: wrap;
  font-size: 30px;
  width: 100%;
  justify-content: space-between;
}

.sComptitle {
  color: rgb(41, 48, 60);

  margin: 0px;
}

.ServicesCompR {
  display: flex;
  flex-wrap: wrap;
  background: rgb(245, 248, 249);
  color: rgb(34, 33, 32, 0.6);
  margin-bottom: 50px;
  width: 50%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  height: max-content;
  border-radius: 10px;
}

.serviceCompItem {
  display: flex;
  align-items: center;
}

.servicesCompTitle {
  padding-right: 10px;
  padding-left: 10px;
}

.dot {
  background: rgb(34, 33, 32, 0.6);
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.hrServices {
  border: 1px dashed black;
  width: 100px;
  margin: 0px 10px;
}

@media only screen and (max-width: 1050px) {
  .hrServices {
    border: 0px dashed black;
    width: 0px;
    margin: 0px 10px;
  }
}

.portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: rgb(41, 48, 60);
}

.portFComp {
  display: flex;
  flex-wrap: wrap-reverse;
  width: 100%;
  margin-bottom: 150px;
  justify-content: space-evenly;
}

.reverse {
  display: flex;
  width: 100%;
  flex-wrap: wrap-reverse;
  flex-direction: row-reverse;
  justify-content: space-evenly;
}

.portFCompImg {
  height: 500px;
  width: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 0.2s ease-in-out;
}
/* .portFCompImg:hover {
  transform: scale(1.8) translate(-25%, 10%);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
} */

.portFCompImgUneven {
  height: 500px;
  width: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 0.2s ease-in-out;
}

/* .portFCompImgUneven:hover {
  transform: scale(1.8) translate(25%, 10%);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
} */

.portFCompText {
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 20px;
  max-width: 650px;
}

.portFCompTitle {
  color: rgb(41, 48, 60);
  font-size: 40px;
  margin-top: 0px;
}

.portFCompBody {
  font-size: 30px;
}

.portFBtnActive {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 10px 15px;
  color: rgb(255, 255, 255);
  background: rgb(110, 118, 126);
  border-radius: 10px;
  font-size: 20px;
  outline: none;
  transition: all 0.2s ease-in-out;
  border: none;
}

.portFBtnActive:hover {
  cursor: pointer;
  background: rgba(15, 44, 77, 0.75);
}

.portFBtnInactive {
  background: rgba(15, 44, 77, 0.216);
}

@media only screen and (max-width: 950px) {
  .portFComp {
    margin-bottom: 100px;
  }

  .portFCompImg {
    height: 216px;
    width: 350px;
  }

  .portFCompImgUneven {
    height: 216px;
    width: 350px;
  }
}

@media only screen and (max-width: 1592px) {
  .portFCompImg {
    margin-bottom: 40px;
  }

  .portFCompImgUneven {
    margin-bottom: 40px;
  }

  .portFCompImg:hover {
    transform: scale(1) translate(0%, 0%);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  .portFCompImgUneven:hover {
    transform: scale(1) translate(0%, 0%);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
}

.lines {
  display: flex;
  position: fixed;
  justify-content: space-between;
  left: 0px;
  top: 0px;
  width: 50px;
  height: 100%;
  z-index: 99;
}
.lLine {
  position: relative;
  width: 12px;
  height: 100%;
  /* background: rgb(21, 126, 252); */
  background: rgb(41, 48, 60);
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}

.mLine {
  position: relative;
  width: 12px;
  height: 92%;
  /* background: rgb(29, 100, 191); */
  background: rgb(80, 89, 102);

  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  z-index: 99;
  transition: all 0.2s ease-in-out;
}

.rLine {
  position: relative;
  width: 12px;
  height: 81%;
  /* background: rgb(13, 42, 79); */
  background: rgb(110, 118, 126);

  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  z-index: 2;
}

.btnLabel {
  font-family: 'Robot+Slab';

  position: absolute;
  bottom: 0px;
  left: 12px;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  padding: 10px 15px;
  color: rgba(255, 255, 255, 0);
  background: rgba(255, 255, 255, 0);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 20px;
  outline: none;
  transition: all 0.2s ease-in-out;
  border: 0px solid rgba(255, 255, 255, 0);
  z-index: -99;
  transform: scaleY(0);
}

.btnLabelMobile {
  display: none;
}

.btnMLabelVis {
  color: rgba(80, 89, 102);
  /* background: rgba(29, 99, 191, 0.504); */
  background: rgb(255, 255, 255);
  border: 1px solid rgba(80, 89, 102);
  transform: scaleY(1);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  width: 190px;
}

.btnMLabelVis::before {
  position: absolute;
  content: '';
  top: -31px;
  left: -1px;
  width: 30px;
  height: 30px;
  background: rgb(255, 255, 255);
  /* box-shadow: -12px 0px 0 0 rgba(29, 99, 191, 0.504); */
  box-shadow: -12px 0px 0 0 rgba(80, 89, 102);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);

  z-index: -99;
  border-bottom-left-radius: 10px;
}

.btnRLabelVis {
  color: rgb(255, 255, 255);
  background: rgba(110, 118, 126);
  transform: scaleY(1);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  width: 130px;
}

.btnRLabelVis::before {
  position: absolute;
  content: '';
  top: -30px;
  left: 0px;
  width: 30px;
  height: 30px;
  background: rgb(255, 255, 255);
  box-shadow: -12px 0px 0 0 rgba(110, 118, 126);

  -webkit-backdrop-filter: blur(8px);

          backdrop-filter: blur(8px);

  z-index: -99;
  border-bottom-left-radius: 10px;
}

.btnLabel:hover {
  box-sizing: border-box;
  opacity: 0.9;
  cursor: pointer;
  width: inherit;
  /* border-right: 3px solid rgba(0, 0, 0, 0.5); */
}

.mLine:hover .btnLabel {
  position: absolute;
  bottom: 0px;
  left: 12px;
  width: 190px;
  padding: 10px 15px;
  color: rgb(255, 255, 255);
  background: rgba(80, 89, 102, 0.75);
  border: 1px solid rgba(80, 89, 102, 0);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 20px;
  outline: none;
  transition: all 0.2s ease-in-out;
  transform: scaleY(1);
}

.mLine:hover .btnLabel::before {
  position: absolute;
  content: '';
  top: -31px;
  left: -1px;
  width: 30px;
  height: 30px;
  background: rgb(255, 255, 255);
  box-shadow: -12px 0px 0 0 rgba(80, 89, 102, 0.75);

  z-index: 99;
  border-bottom-left-radius: 10px;
}

.mLine:hover {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 15px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.rLine:hover .btnLabel {
  position: absolute;
  bottom: 0px;
  left: 12px;
  width: 130px;
  padding: 10px 15px;
  color: rgb(255, 255, 255);
  /* background: rgba(13, 42, 79, 0.504); */
  background: rgba(110, 118, 126, 0.75);

  -webkit-backdrop-filter: blur(8px);

          backdrop-filter: blur(8px);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 20px;
  outline: none;
  transition: all 0.2s ease-in-out;
  transform: scaleY(1);
}

.rLine:hover .btnLabel::before {
  position: absolute;
  content: '';
  top: -30px;
  left: 0px;
  width: 30px;
  height: 30px;
  background: rgb(255, 255, 255);
  /* box-shadow: -12px 0px 0 0 rgba(13, 42, 79, 0.504); */
  box-shadow: -12px 0px 0 0 rgba(110, 118, 126, 0.75);

  z-index: 99;
  border-bottom-left-radius: 10px;
}

.rLine:hover {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 15px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.lineVisible {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 15px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.footer {
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  bottom: 0px;
  left: 12px;
  width: 100vw;
  height: 50px;
  background: rgb(255, 255, 255);
  color: white;
  align-items: center;
  transform: translate(0%, 100%);
  transition: all 0.2s ease-in-out;
}

.footerVis {
  transform: translate(0%, 0%);
  /* background: rgb(21, 126, 252); */
  background: rgb(41, 48, 60);
}

.footerVis::before {
  position: absolute;
  content: '';
  top: -30px;
  left: 0px;
  width: 30px;
  height: 30px;
  background: rgb(255, 255, 255);
  /* box-shadow: 0 15px 0 0 rgb(21, 126, 252); */
  box-shadow: 0 15px 0 0 rgb(41, 48, 60);

  z-index: 99;
  border-bottom-left-radius: 15px;
}

.footerTitle {
}

.impressum {
  text-decoration: none;
  color: white;
}

.btnMarker {
  position: absolute;
  bottom: 0%;
  left: 50%;
  width: 30%;
  height: 10px;
  transform: translate(-50%, -50%);

  border-radius: 10px;
  background: white;
  transition: all 0.2s ease-in-out;
}

.mLine:hover .btnMarker {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.rLine:hover .btnMarker {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

@media only screen and (max-width: 1050px) {
  .lLine {
    height: 100%;
  }

  .mLine {
    height: 45vh;
  }

  .rLine {
    height: 20vh;
  }

  .impressum {
    text-decoration: none;
    color: white;
  }
}

@media only screen and (max-width: 600px) {
  .lines {
    display: none;
  }

  .btnLabelMobile {
    display: block;
    font-family: 'Robot+Slab';

    min-width: 100%;
    padding: 10px 15px;
    color: rgba(255, 255, 255);
    background: rgb(80, 89, 102);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 20px;
    outline: none;
    transition: all 0.2s ease-in-out;
    border: 0px solid rgb(80, 89, 102);
    border-radius: 10px;
    margin-bottom: 40px;
  }

  .btnLabelUnMarked {
    background: white;
    border: 1px solid rgb(80, 89, 102);
    color: rgb(80, 89, 102);
  }
}

.skydive {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  color: rgb(34, 33, 32);
}

.skydiveTitle {
  font-size: 50px;
  margin: 50px;
}

.skydiveExp {
  margin-bottom: 100px;
}

.galleryTitle {
  font-size: 30px;
  margin: 30px;
}

.galleryMainImg {
  width: 100%;
  height: 800px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.galleryGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.gridItem {
  position: relative;
  width: 700px;
  height: 480px;
  margin: 25px;
}

.gridItemImg {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 0px;
}

.gridItemTxt {
  position: absolute;
  text-align: left;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
  opacity: 0;
  font-size: 30px;
  margin: 25px;

  color: white;
}

.gridItemTxt:hover {
  cursor: text;
}

.gridItemTitle {
  font-size: 30px;
  margin-bottom: 20px;
}

.gridItemDesc {
  font-size: 20px;
  width: 100%;
}

.gridItem:hover > .gridItemImg {
  -webkit-filter: brightness(30%);
  transition: all 0.5s ease;
}

.gridItem:hover > .gridItemTxt {
  opacity: 1;
  transition: all 0.5s ease;
}

