.bio {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'Tangerine';
  margin-bottom: 200px;
}

.bioTop {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 70px;
}

.bioBot {
  margin-top: 100px;
  align-self: center;
}

.bioTopL {
  display: flex;
}

.bioTopText {
  margin-top: 100px;

  text-align: left;
}

.bioTitle {
  font-size: 50px;
}

.bioSubTitle {
  font-size: 30px;
  color: rgb(34, 33, 32, 0.6);
}

.bioContent {
  font-size: 30px;
  color: rgb(34, 33, 32, 0.6);

  max-width: 500px;
}

.bioImg {
  margin-top: 100px;
  filter: grayscale(100%);
  border-radius: 100%;
  width: 350px;
  height: 350px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.25s ease-in-out;
}

.bioImg:hover {
  filter: grayscale(0%);
  transition: all 0.25s ease-in-out;
}

.bioBody {
  font-size: 30px;
  max-width: 700px;
  text-align: center;
}

.devider {
  border: 1px solid rgb(34, 33, 32, 0.6);
  width: 50%;
  margin: 100px auto;
}

@media only screen and (max-width: 1053px) {
  .bioTop {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }
}

@media only screen and (max-width: 500px) {
  .bioImg {
    width: 300px;
    height: 300px;
    margin-top: 180px;
  }
}
