.skydive {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  color: rgb(34, 33, 32);
}

.skydiveTitle {
  font-size: 50px;
  margin: 50px;
}

.skydiveExp {
  margin-bottom: 100px;
}

.galleryTitle {
  font-size: 30px;
  margin: 30px;
}

.galleryMainImg {
  width: 100%;
  height: 800px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.galleryGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.gridItem {
  position: relative;
  width: 700px;
  height: 480px;
  margin: 25px;
}

.gridItemImg {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 0px;
}

.gridItemTxt {
  position: absolute;
  text-align: left;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
  opacity: 0;
  font-size: 30px;
  margin: 25px;

  color: white;
}

.gridItemTxt:hover {
  cursor: text;
}

.gridItemTitle {
  font-size: 30px;
  margin-bottom: 20px;
}

.gridItemDesc {
  font-size: 20px;
  width: 100%;
}

.gridItem:hover > .gridItemImg {
  -webkit-filter: brightness(30%);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.gridItem:hover > .gridItemTxt {
  opacity: 1;

  -webkit-transition: all 1s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
